import React from 'react'
import { Link } from 'gatsby'
import Header from './Header';
import Footer from './footer';
import { rhythm, scale } from '../utils/typography';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import './Layout.css'

class Layout extends React.Component {
  render() {
    const { location,children } = this.props

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
      <Header location={location} />

        {children}

      <Footer />

      </div>
    )
  }
}

export default Layout
