import React from "react"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { rhythm, scale } from '../utils/typography';
import { Link } from 'gatsby'

export default (props) => {
const { title } = useSiteMetadata();

  const rootPath = `${__PATH_PREFIX__}/`
  let header;
  if (props.location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
          marginBottom: rhythm(-1),
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return <div>{header}</div>;
}
